import React, { Suspense, lazy, useEffect, useState } from "react";
import { Route, Routes, useLocation,Outlet } from "react-router-dom";
import Navbar from "../components/CommonComponents/Navbar";
import Footer from "../components/CommonComponents/Footer";

//open emr
import Login from "../OpenemrComponents/Login";
import Profile from "../OpenemrComponents/Profile";

import Register from "../OpenemrComponents/Signup";
import UserProvider from "../Context/UserContext";

//import Home from "./Screens/Home";
//import Contact from "./Screens/ContactUs";
import Admin from "../OpenemrComponents/Admin";
import UserAndEnquiryList from "../OpenemrComponents/UserAndEnquiryList";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const About = lazy(() => import("../Screens/AboutUs"));
const Contact = lazy(() => import("../Screens/ContactUs"));
const DentalImplants = lazy(() => import("../Screens/DentalImplants"));
const Invisalign = lazy(() => import("../Screens/Invisalign"));
const Denplan = lazy(() => import("../Screens/Denplan"));
const BookAppointment = lazy(() => import("../Screens/BookAppointment"));
const PrivateFees = lazy(() => import("../Screens/PrivateFees"));
const NHSFees = lazy(() => import("../Screens/NHSFees"));
const Treatments = lazy(() => import("../Screens/Treatments"));
const Courses = lazy(() => import("../Screens/Courses"));
const SurgicalGuide = lazy(() => import("../Screens/SurgicalGuide"));
const Referrals = lazy(() => import("../Screens/Referrals"));
const ForDentist = lazy(() => import("../Screens/ForDentist"));
const PatientInformation = lazy(() => import("../Screens/PatientInformation"));
const ImplantCare = lazy(() => import("../Screens/ImplantCare"));
const DigitalPlanning = lazy(() => import("../Screens/DigitalPlanning"));
const Home = lazy(() => import("../Screens/Home"));

function Index() {
  const [selectedOption, setSelectedOption] = useState(
    "Rosebrough Dental Practice"
  );

  const location = useLocation();
  const path = location.pathname;
  useEffect(() => {
    window.scroll(0, 0);
  }, [path]);
  const selectRoute =
    location.pathname !== "/" &&
    location.pathname !== "/about-us" &&
    location.pathname !== "/referrals" &&
    location.pathname !== "/surgical-guides"
      ? false
      : true;


      const Layout = () => {
        return (
          <div>
            <Navbar
              path={selectRoute}
              selectedOption={selectedOption}
              setSelectedOption={setSelectedOption}
            />
            <Outlet />
    
            <Footer />
          </div>
        );
      };
    


  return (
    <>
      {/* <Navbar
        path={selectRoute}
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
      /> */}
      <UserProvider>
        <ToastContainer position="top-center" autoClose={5000} />
        <Routes>
        <Route exact path="/" element={<Layout />}>
          <Route path="/" element={<Home selectedOption={selectedOption} />} />
          <Route
            path="/contact-us"
            element={<Contact selectedOption={selectedOption} />}
          />
          <Route
            path="/denplan"
            element={<Denplan selectedOption={selectedOption} />}
          />

          <Route
            path="/about-us"
            element={<About selectedOption={selectedOption} />}
          />

          <Route path="/dental-implants" element={<DentalImplants />} />
          <Route
            path="/treatments"
            element={<Treatments selectedOption={selectedOption} />}
          />
          <Route
            path="/dental-implants/digital-planning"
            element={<DigitalPlanning />}
          />
          <Route
            path="/dental-implants/gum-&-implant-care"
            element={<ImplantCare />}
          />
          <Route
            path="/dental-implants/patient-information"
            element={<PatientInformation />}
          />
          <Route path="/dental-implants/for-dentist" element={<ForDentist />} />
          <Route
            path="/invisalign"
            element={<Invisalign selectedOption={selectedOption} />}
          />
          <Route path="/book-appointments" element={<BookAppointment />} />
          <Route
            path="/private-fee"
            element={<PrivateFees selectedOption={selectedOption} />}
          />
          <Route path="/referrals" element={<Referrals />} />
          <Route path="/surgical-guides" element={<SurgicalGuide />} />
          <Route path="/our-courses" element={<Courses />} />
          <Route path="/nhs-fee" element={<NHSFees />} /> 
          </Route>

          {/* <Route path="/blog" element={ <Blog/>} />
        <Route path="/blogdetails" element={ <BlogDdeatils/>} />
        <Route path="/service" element={ <Service/>} />
        <Route path="/singleservice" element={ <Servicedeatils/>} />
        <Route path="/gallery" element={ <Gallery/>} />
        <Route path="/pricing" element={ <Pricing/>} />
        <Route path="/faq" element={ <Faq/>} />
        <Route path="/team" element={ <Team/>} />
        <Route path="/teamdetails" element={ <TeamDetails/>} />
        <Route path="/shop" element={ <Shop/>} />
        <Route path="/Shopdetails" element={ <ShopDetails/>} />
        <Route path="/hometwo" element={ <Hometwo/>} /> */}

          <Route exact path="/login" element={<Login />} />
          <Route exact path="/register" element={<Register />} />
          <Route exact path="/profile/:role" element={<Profile />} />
          <Route exact path="/admin" element={<Admin />} />

          <Route path="/admin/:table" element={<UserAndEnquiryList />} />
        </Routes>
      </UserProvider>

      {/* <Footer /> */}
    </>
  );
}

export default Index;
