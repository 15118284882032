export const enquiresFormTableColumns = [
  {
    header: "Patient Name",
    accessorKey: "name",
   
  },
  
  {
      header: "Patient ID",
      accessorKey: "identity",
     
    },
    
    {
      header: "Email ID",
      accessorKey: "email_id",
      
    },
    {
      header: "Contact Number",
      accessorKey: "phone",
    
    },
    {
      header:"Created At",
      accessorFn: (row) => row?.child_object[0]?.created_at.slice(0, 16),
     
    }
    
   
  ];
 

export const userTableColumns = [
    {
        header: "Patient Id",
        accessorKey: "identity",
       
      },
      {
          header: "Name",
          accessorFn: (row) => `${row.first_name} ${row.last_name}`,
         
         
        },
      {
        header: "Email Id",
        accessorKey: "email_id",
      
      },
      {
        header: "Contact Number",
        accessorKey: "phone_number",
       
      },
      {
        header:"Postal Code",
        accessorKey:"postal_code"
      },
      {
        header:"Created at",
        accessorFn: (row) => row.created_at.slice(0, 16),
       
      }
      
      
     
    ];

