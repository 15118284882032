import React from "react";
import { useParams } from "react-router";

const Profile = () => {
    const {role} = useParams()

  return (
    <div className="bg-black h-screen text-white">
      <div className="flex flex-col justify-center items-center text-center  h-full text-white">
            <p className = "text-xl "> i am logging  with {role} role </p>
      </div>
    </div>
  );
};

export default Profile;