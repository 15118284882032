
import React, {  useEffect, useContext } from "react";
import { useNavigate} from "react-router-dom";

import { UserContext } from "../Context/UserContext";
import Enquires from "../assets/enquires.png"
import Users from "../assets/users.png"




const Admin = () => {
 
  const { users, setUsers } = useContext(UserContext);
  const token = users?.token;

  const navigate = useNavigate();


  useEffect(() => {
    if (!token ) {
      navigate("/login");
    }
  }, [token, navigate]);

  const url = process.env.REACT_APP_API_URL;
console.log({url})

  const handleLogout = () => {
    //localStorage.removeItem("userData");
    setUsers(null); // Clear user data from context
    navigate("/login");
  };

  
  return (
    <div className=" min-h-screen  bg-[#F9F8F8]">
      {/* Header */}
      <header className="bg-[#4838C8] text-white py-4 px-6 flex justify-between items-center  font-poppins">
        <h1 className="text-xl font-semibold ">Admin</h1>
        <button
          onClick={handleLogout}
          className="text-md hover:underline focus:outline-none"
        >
          Logout
        </button>
      </header>

      {/* Main content */}
      <main>
        <div className="max-w-2xl mx-auto p-20">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-24 lg:gap-40 font-poppins font-medium">
            {/* Enquiries Button */}
            
              <button
                onClick={() => navigate("/admin/enquiries")}
                className="flex items-center justify-center gap-3 p-4 bg-white rounded-lg border border-[#999999] shadow-sm hover:bg-gray-50 transition-colors"
              >
                <img src = {Enquires} alt = "enquires img"  className="w-8 h-8"/>
                <span className="text-gray-800 font-medium">ENQUIRIES</span>
              </button>

            {/* Users Button */}
          
                <button
                onClick={() => navigate("/admin/users")}
                className="flex items-center justify-center gap-3 p-6 bg-white rounded-lg border border-[#999999] shadow-sm hover:bg-gray-50 transition-colors"
              >
                  <img src = {Users} alt = "enquires img"  className="w-8 h-7"/>

                <span className="text-gray-800 font-medium">USERS</span>
              </button>   
          </div>

         
        </div>
      </main>
    </div>
  );
};

export default Admin;


// import React, {  useEffect, useContext } from "react";
// import { useNavigate } from "react-router-dom"
// import { UserContext } from "../Context/UserContext";

// export default function Admin() {
//   const navigate = useNavigate()
//   const { users,logout} = useContext(UserContext);

//   // Protect route
// useEffect(() => {
//     if (!users?.token) {
//       navigate("/login")
//     }
//   }, [users, navigate])

//   return (
//     <div className="min-h-screen bg-[#F9F8F8]">
//       <header className="bg-[#4838C8] text-white p-4 flex justify-between items-center">
//         <h1 className="text-xl font-semibold">Admin</h1>
//         <button
//           onClick={logout}
//           className="hover:underline focus:outline-none"
//         >
//           Logout
//         </button>
//       </header>

//       <main className="p-6">
//         <div className="max-w-2xl mx-auto grid grid-cols-1 md:grid-cols-2 gap-6">
//           <button
//             onClick={() => navigate("/admin/enquiries")}
//             className="flex items-center justify-center gap-3 p-4 bg-white rounded-lg border border-neutral-200 shadow-sm hover:bg-neutral-50 transition-colors"
//           >
//             <span className="sr-only">View Enquiries</span>
//             <svg
//               className="w-6 h-6 text-neutral-600"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
//               />
//             </svg>
//             <span className="font-medium">Enquiries</span>
//           </button>

//           <button
//             onClick={() => navigate("/admin/users")}
//             className="flex items-center justify-center gap-3 p-4 bg-white rounded-lg border border-neutral-200 shadow-sm hover:bg-neutral-50 transition-colors"
//           >
//             <span className="sr-only">View Users</span>
//             <svg
//               className="w-6 h-6 text-neutral-600"
//               fill="none"
//               viewBox="0 0 24 24"
//               stroke="currentColor"
//             >
//               <path
//                 strokeLinecap="round"
//                 strokeLinejoin="round"
//                 strokeWidth={2}
//                 d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
//               />
//             </svg>
//             <span className="font-medium">Users</span>
//           </button>
//         </div>
//       </main>
//     </div>
//   )
// }



