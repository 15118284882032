import React, {
  useState,
  useEffect,
  useMemo,
  useContext,
  useCallback,
} from "react";
import { enquiresFormTableColumns, userTableColumns } from "../Utils";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";

import Sidebar from "./Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import axios from "axios";
import { toast } from "react-toastify";

const UserAndEnquiryList = () => {
  const [loading, setLoading] = useState(false);
  const [enquiresList, setEnquiresList] = useState([]);
  const [usersList, setUsersList] = useState([]);
  const [searchWord, setSearchWord] = useState("");
  const [columnSorting, setColumnSorting] = useState([]);
  const [isMenuOpen, setIsMenuOpen] = useState("false");
  const [enquires, setEnquires] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [fileLoading, setFileLoading] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const { users } = useContext(UserContext);
  const token = users?.token;
  const navigate = useNavigate();
  const { table } = useParams();

  useEffect(() => {
    if (!token) {
      toast.error("Session Expired", "Please log in again.");
      navigate("/login");
    }
  }, [token, navigate]); 

  const url = process.env.REACT_APP_API_URL 
  console.log("url",url)

  const handleLogout = () => {
    localStorage.removeItem("userData");
    navigate("/login");
  };

  //console.log("id", selectedId);
  useEffect(() => {
    setIsSidebarVisible(false);
    setEnquires(null);
    setUploadedFiles([]);
    setSelectedId(null);
  }, [table]);


  const fetchData = useCallback(async () => {
    if (!table) return; // Ensure table parameter exists

    try {
      setLoading(true);
      const url = `${process.env.REACT_APP_NEW_API_URL}${
        table === "enquiries" ? "/get-all-enquiries" : "/get-all-users"
      }`;

      const response = await axios.get(url, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 200) {
        const newData = response.data.data.details;
        table === "enquiries"
          ? setEnquiresList(newData)
          : setUsersList(newData);
      }
    } catch (error) {
      console.error(`Error fetching ${table} data:`, error);
      if (error.response?.status === 401 || error.response?.status === 400) {
        navigate("/login");
      }
    } finally {
      setLoading(false);
    }
  }, [table, token, navigate]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

  const openSidebar = (enquiry) => {
    setEnquires(enquiry);
    setIsSidebarVisible(true);
    fetchUploadedFiles(enquiry.identity);
    setSelectedId(enquiry?.id);
  };

  const closeSidebar = () => {
    setIsSidebarVisible(false);
    setEnquires(null);
    setUploadedFiles([]);
    setSelectedId(null);
  };

  const data = useMemo(
    () => (table === "enquiries" ? enquiresList : usersList),
    [table, enquiresList, usersList]
  );

  const columns = useMemo(
    () => (table === "enquiries" ? enquiresFormTableColumns : userTableColumns),
    [table]
  );

  const tableData = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    state: {
      globalFilter: searchWord,
      columnSorting,
    },
    onGlobalFilterChange: setSearchWord,
    onColumnSortingChange: setColumnSorting,
  });

  const fetchUploadedFiles = useCallback(
    async (identity) => {
      if (!identity || !token) return;

      try {
        setFileLoading(true);
        const response = await axios.get(
          `${process.env.REACT_APP_NEW_API_URL}/get-all-saved-documents?identity=${identity}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        if (response.status === 200) {
          setUploadedFiles(response.data.data.details.documents);
        }
      } catch (error) {
        console.error("Error fetching files:", error);
        if (error.response?.status === 401) {
          navigate("/login");
        }
      } finally {
        setFileLoading(false);
      }
    },
    [token, navigate]
  );

  console.log("uploaded files in side", uploadedFiles);

  useEffect(() => {
    if (isSidebarVisible) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }

    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [isSidebarVisible]);

  return (
    <div>
      {/* Header */}
      <div className="bg-[#4838C8] h-[10vh] px-4 flex items-center justify-between w-full rounded-t-lg font-poppins">
        <svg
          className="w-8 h-8 text-white cursor-pointer"
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          fill="none"
          viewBox="0 0 24 24"
          onClick={toggleMenu}
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeWidth="2"
            d="M5 7h14M5 12h14M5 17h14"
          />
        </svg>

        <h3 className=" text-white cursor-pointer" onClick={handleLogout}>
          Logout
        </h3>
      </div>

      {/* Main content */}
      <div>
        <div className="flex min-h-[calc(100vh-90px)] overflow-x-hidden">
          {/* Sidebar */}
          <div
            className={`transition-width duration-300 overflow-hidden bg-gray-900 ${
              isMenuOpen ? "w-48" : "w-0"
            } text-white`}
          >
            <div className="p-4">
              <h2 className="text-xl font-semibold">Menu</h2>
              <ul className="mt-6">
                <li
                  className="cursor-pointer hover:bg-gray-700 p-2 rounded"
                  // onClick={() => handleMenuClick("enquiries")}
                  onClick={() => navigate("/admin/enquiries")}
                >
                  Enquiries
                </li>
                <li
                  className="cursor-pointer hover:bg-gray-700 p-2 rounded"
                  //  onClick={() => handleMenuClick("users")}
                  onClick={() => navigate("/admin/users")}
                >
                  Users
                </li>
              </ul>
            </div>
          </div>

          {/* Table content */}
          <div className="flex-grow bg-[#F9F8F8] p-4 font-poppins min-h-[calc(100vh-90px)] overflow-x-hidden">
            <div>
              <div className="flex flex-col items-start justify-start gap-7 min-h-[100vh]">
                {loading ? (
                  <div className="flex justify-center w-full mt-20">
                    {/* Loading spinner */}
                    <svg
                      className="inline w-7 h-7 text-[#4838C8] animate-spin fill-white"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                  </div>
                ) : data.length > 0 ? (
                  <div className="w-full flex flex-col items-start justify-start gap-5 min-h-[calc(100vh-12vh)] p-4 overflow-x-auto">
                    <div className="w-full justify-between items-center flex ">
                      <p className="text-xl font-semibold">
                        List of {table === "enquiries" ? "Enquiries" : "Users"}
                      </p>
                      <div className="flex items-center gap-3">
                        <div className="border rounded-full py-[10px] px-3 text-xs border-gray-300 flex gap-2 items-center">
                          {searchWord ? (
                            <button onChange={() => setSearchWord("")}>
                              <svg
                                className="w-4 h-4 text-gray-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                              >
                                <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5Zm3.707 11.793a1 1 0 1 1-1.414 1.414L10 11.414l-2.293 2.293a1 1 0 0 1-1.414-1.414L8.586 10 6.293 7.707a1 1 0 0 1 1.414-1.414L10 8.586l2.293-2.293a1 1 0 0 1 1.414 1.414L11.414 10l2.293 2.293Z" />
                              </svg>
                            </button>
                          ) : (
                            <button>
                              <svg
                                className="w-4 h-4 text-gray-500 cursor-pointer"
                                fill="none"
                                viewBox="0 0 20 20"
                              >
                                <path
                                  stroke="currentColor"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth="2"
                                  d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                                />
                              </svg>
                            </button>
                          )}
                          <input
                            type="text"
                            placeholder="Search"
                            className="w-[85%] outline-none border-none bg-transparent"
                            value={searchWord}
                            onChange={(e) => setSearchWord(e?.target?.value)}
                            aria-label="Search input"
                          />
                        </div>
                      </div>
                    </div>
                    <table className="w-full ">
                      <thead className="min-w-full text-sm border-[#4838C8] border cursor-pointer">
                        {tableData.getHeaderGroups().map((headerGroup) => (
                          <tr
                            key={headerGroup.id}
                            className="rounded-t-md bg-[#4838C8]  text-white text-sm divide-x divide-gray-400"
                          >
                            {headerGroup.headers.map((header) => (
                              <th
                                key={header.id}
                                className={`py-[9.5px] px-4 text-start font-medium ${
                                  users?.role_id !== 1 && ""
                                }`}
                                onClick={header.column.getToggleSortingHandler()}
                              >
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                                <span className="text-white">
                                  {{
                                    asc: " ▲",
                                    desc: " ▼",
                                  }[header.column.getIsSorted()] ?? ""}
                                </span>
                              </th>
                            ))}
                          </tr>
                        ))}
                      </thead>
                      <tbody className="bg-white divide-y divide-[#C0C0C0] border-b border-[#C0C0C0]">
                        {tableData.getRowModel().rows.map((row) => (
                          <tr
                            key={row.id}
                            className={`divide-x divide-[#C0C0C0] border-x border-[#C0C0C0] rounded-b-md text-start text-sm cursor-pointer
                              ${
                                selectedId === row.original.id
                                  ? "bg-[#DCEFF4]" // Apply background color for the active row
                                  : "hover:bg-[#dae1f072]" // Apply hover background color for other rows
                              }

                          `}
                            onClick={() =>
                              table === "enquiries" &&
                              openSidebar(row?.original)
                            }
                          >
                            {row.getVisibleCells().map((cell) => (
                              <td key={cell.id} className="py-[9.5px] px-4 ">
                                {flexRender(
                                  cell.column.columnDef.cell,
                                  cell.getContext()
                                )}
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <div className="w-full flex justify-center items-center min-h-[300px]">
                    <p>
                      No {table === "enquiries" ? "enquires" : "Users"} Found.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {isSidebarVisible && (
        <Sidebar
          isSidebarVisible={isSidebarVisible}
          closeSidebar={closeSidebar}
          enquires={enquires}
          uploadedFiles={uploadedFiles}
          loading={fileLoading}
          fetchUploadedFiles={fetchUploadedFiles}
        />
      )}
    </div>
  );
};

export default UserAndEnquiryList;
