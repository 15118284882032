

import axios from "axios";
import { Link } from "react-router-dom"
import React, { useState ,useContext} from "react";
import { UserContext } from "../Context/UserContext";

const UploadedFiles = (props) => {
  const { enquires, uploadedFiles, loading } = props;
  const { identity } = enquires;
  console.log(uploadedFiles)
  
  const [isUplodedFileVisible, setIsUplodedVisible] = useState(false);

  const { users } = useContext(UserContext);
  const token = users?.token;
  console.log(token,"f")

  // const files = [
  //   { id: 1, name: "S.NO" },
  //   { id: 2, name: "Uploaded at" },
  //   { id: 3, name: "Uploaded by" },
  //   { id: 4, name: "Doc Type" },
  //   { id: 5, name: "Doc " },
  // ];
  const files = [
    { id: 1, name: "S.NO", width: 60 },
    { id: 2, name: "Uploaded at", width: 160 },
    { id: 3, name: "Uploaded by", width: 160 },
    { id: 4, name: "Doc Type", width: 160 },
    { id: 5, name: "Doc", width: 200 },
  ];

  const getPresingnedDocUrl = async (filename) => {
    
    // try {
    //   const response = await axios.get(
    //     `${process.env.REACT_APP_API_URL}/get-pre-signed-s3-url?identifier=${identity}&filename=${filename}`,
    //     {
    //       headers: {
    //         Authorization: `Bearer ${token}`,
    //       },
    //     }
    //   );
    //   console.log(response,"res")
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_NEW_API_URL}/get-pre-signed-s3-url?identifier=${identity}&filename=${filename}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response,"res")
      if (response.data && response.data.data){
        console.log('getPresignedDocurl',response?.data?.data);
        return response.data.data
      }else{
        console.error('Presigned Doc URL not found in the response.');
        return null
      }
      
      
    } catch (error) {
      console.error("Error fetching the presigned URL:", error.response.data);
    }
  };

  const downloadFile = async (filename) => {
    const presignedDocUrlResponse = await getPresingnedDocUrl(filename);
    if (!presignedDocUrlResponse) {
      console.error("Presigned Doc URL is null or undefined.");
      return;
    }
  
    const presignedUrl = presignedDocUrlResponse.url;
    // Download the file
    try {
      const anchor = document.createElement("a");
      anchor.href = presignedUrl;
  
      // Force download with a filename
      anchor.download = filename;
      document.body.appendChild(anchor);
      anchor.target = "_blank";
      anchor.click();
      document.body.removeChild(anchor);
    } catch (error) {
      console.error("Error during file download:", error);
    }
  };
  
  return (
    <div >
      <div className="w-full shadow-sm outline-none border border-gray-300 bg-slate-200 text-gray-900 rounded-lg focus:ring-[#4838C8] focus:border-[#4838C8] block p-3 my-3">
        <div
          className="flex justify-between items-center cursor-pointer"
          onClick={() => setIsUplodedVisible((prevState) => !prevState)}
        >
          <p className="font-semibold text-xl">Uploaded Doc</p>
          <div>
            {isUplodedFileVisible ? (
              <svg
                className="w-6 h-6 text-gray-800 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m16 14-4-4-4 4"
                />
              </svg>
            ) : (
              <svg
                className="w-6 h-6 text-gray-800 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="m8 10 4 4 4-4"
                />
              </svg>
            )}
          </div>
        </div>
      </div>
      {isUplodedFileVisible &&
        (loading ? (
          <div>Loading...</div>
        ) : uploadedFiles.length > 0 ? (
          <div className="w-full font-poppins mt-2 mx-auto overflow-x-auto">
            <table className="w-full border-collapse">
              <thead className="">
                <tr className="">
                  {files.map((file) => (
                    <th key={file.id} className="bg-[#4838C8] text-white p-3 text-left text-sm font-medium border border-gray-400"
                    style={{ width: file.width }}
                    >
                      {file.name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="">
                {uploadedFiles.map((file, index) => (
                  <tr
                    key={file.id}
                    className="border hover:bg-[#DCEFF4] transition-colors   cursor-pointer"
                  >
                    <td className="px-4 py-2">{index + 1}</td>
                    <td className="px-4 py-2">
                      {file?.created_at?.slice(0, 16)}
                    </td>
                    <td className="px-4 py-2">{file?.added_by_name}</td>
                    <td className="px-4 py-2">{file?.file_type}</td>
                    <td
                      className="px-4 py-2 capitalize truncate max-w-xs   "
                     
                      onClick={() => downloadFile(file?.file_name)}
                    >
                    
                      <Link to = "" className="text-blue-500 underline hover:text-blue-700">{file?.file_name}</Link>
                    </td>
                    {/* <td className="py-[8px] px-2">
                      <a
                        href={file?.doc_url}
                        className="text-blue-500 underline"
                        onClick={() => downloadFile(file?.doc_url)}
                      >
                        {file?.doc_url}
                      </a>
                    </td> */}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div className="text-center py-5">No files uploaded yet</div>
        ))}
    </div>
  );
};

export default UploadedFiles;
