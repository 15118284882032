
import { useState, useCallback } from "react";
import FileUploades from "./FileUploades";
import UploadedFiles from "./UploadedFiles";

const Sidebar = ({
  isSidebarVisible,
  closeSidebar,
  enquires,
  uploadedFiles,
  fetchUploadedFiles,
  loading,
}) => {
  const [isEnquiryMessageVisible, setIsEnquiryMessageVisible] = useState(false);
  const [expandedMessageId, setExpandedMessageId] = useState(null);

  console.log({enquires})
  const enquiryHeaders = [
    { id: 1, name: "Name" },
    { id: 2, name: "Email ID" },
    { id: 3, name: "Phone Number" },
  ];

  const contactFormHeader = [
    { id: 1, name: "Uploaded" },
    { id: 2, name: "Subject" },
    { id: 3, name: "Clinic_Name" },
    { id: 4, name: "Message" },
  ];

  const handleFileUpload = useCallback(() => {
    console.log("i",enquires?.identity)
    fetchUploadedFiles(enquires?.identity);
  }, [fetchUploadedFiles, enquires?.identity]);

  const toggleReadMore = (id) => {
    setExpandedMessageId((prevId) => (prevId === id ? null : id));
  };

  const getShortMessage = (message, id) => {
    const isExpanded = expandedMessageId === id;
    return isExpanded || message.length <= 40
      ? message
      : `${message.slice(0, 40)}...`;
  };

  return (
    <div
      className={`fixed top-0 right-0 h-screen lg:w-2/3 md:w-1/2 w-[500px] bg-white shadow-lg text-black z-20 transition-transform duration-700 ease-in-out ${
        isSidebarVisible ? "translate-x-0" : "translate-x-full"
      } hidden md:block`}
    >
      <div className="sticky top-0 z-30 bg-white p-4 font-poppins">
        <div className="flex justify-between items-center mb-2">
          <h2 className="text-xl font-medium">Patient Details</h2>
          <button
            onClick={closeSidebar}
            className="p-2 rounded-full hover:bg-gray-100 transition-colors"
            aria-label="Close sidebar"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4 12h16M13 5l7 7-7 7"
              />
            </svg>
          </button>
        </div>
  
        {/* Patient Details */}
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr>
                {enquiryHeaders.map((header) => (
                  <th
                    key={header.id}
                    className="bg-[#4838C8] text-white p-3 text-left text-sm font-medium border border-gray-400 font-poppins"
                  >
                    {header.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              <tr className="bg-[#DCEFF4] text-sm">
                <td className="p-3">{enquires.name}</td>
                <td className="p-3">{enquires.email_id}</td>
                <td className="p-3">{enquires.phone}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
  
      {/* Sidebar Content */}
      <div className="px-4 overflow-y-auto h-[calc(100vh-200px)]">
        {/* Enquiries Section */}
        <div className="mb-3">
          <button
            className="w-full p-3 bg-slate-200 text-left font-semibold text-xl rounded-lg hover:bg-slate-300 transition-colors focus:outline-none flex justify-between items-center"
            onClick={() => setIsEnquiryMessageVisible(!isEnquiryMessageVisible)}
          >
            <span>Enquiries</span>
            <svg
              className="w-6 h-6 text-gray-800 transition-transform duration-200"
              style={{
                transform: isEnquiryMessageVisible
                  ? "rotate(-180deg)"
                  : "rotate(0)",
              }}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m8 10 4 4 4-4"
              />
            </svg>
          </button>
  
          {isEnquiryMessageVisible && (
            <div className="mt-2 overflow-x-auto">
              <table className="w-full border-collapse">
                <thead>
                  <tr>
                    {contactFormHeader.map((header) => (
                      <th
                        key={header.id}
                        className="bg-[#4838C8] text-white p-3 text-left text-sm font-medium border border-gray-400"
                      >
                        {header.name}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {enquires?.child_object?.map((object) => (
                    <tr
                      key={object?.enquiry_child_id}
                      className={`border hover:bg-[#DCEFF4] transition-colors 
                      }`}
                    >
                      <td className="p-3 text-sm">
                        {object?.created_at?.slice(0, 16)}
                      </td>
                      <td className="p-3 text-sm">{object?.subject}</td>
                      <td className="p-3 text-sm">{object?.clinic_name}</td>
                      <td className="p-3 text-sm">
                        <div className="space-x-2">
                          <span>
                            {getShortMessage(
                              object?.message,
                              object?.enquiry_child_id
                            )}
                          </span>
                          {object?.message.length > 40 && (
                            <button
                              className="text-[#4838C8] hover:text-[#362ca0] transition-colors whitespace-nowrap"
                              onClick={() =>
                                toggleReadMore(object?.enquiry_child_id)
                              }
                            >
                              {expandedMessageId === object?.enquiry_child_id
                                ? "Read Less"
                                : "Read More"}
                            </button>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
  
        {/* FileUploades Component */}
        <div className="mb-4">
          <FileUploades enquires={enquires} onFileUpload={handleFileUpload} />
        </div>
  
        {/* UploadedFiles Component */}
        <div>
          <UploadedFiles
            enquires={enquires}
            uploadedFiles={uploadedFiles}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
  
};

export default Sidebar;
