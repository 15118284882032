// import React ,{createContext, useState} from 'react'

// export const UserContext = createContext();

// const User = ({ children }) => {
//     const [user, setUser] = useState()
//   return (
//     <UserContext.Provider value={{ user, setUser }}>
//       {children}
//     </UserContext.Provider>
//   )
// }

// export default User

import React, { createContext, useEffect, useState,useCallback   } from "react";

export const UserContext = createContext();

 const UserProvider = ({ children }) => {
  const [users, setUsers] = useState(() => {
    const storedUser = sessionStorage.getItem("users");
    return storedUser ? JSON.parse(storedUser) : null;
  });

  useEffect(() => {
    if (users) {
      sessionStorage.setItem("users", JSON.stringify(users));
    } else {
      sessionStorage.removeItem("users");
    }
  }, [users]);

  const logout = useCallback(() => {
    setUsers(null)
  }, [setUsers])

  return (
    <UserContext.Provider value={{ users, setUsers,logout }}>
      {children}
    </UserContext.Provider>
  );
};

 export default UserProvider;
